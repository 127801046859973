import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
import './Login.css';

const Login = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  if (isAuthenticated) {
    return <Navigate to="/chat" />;
  }

  return (
    <div className="login-container">
      <div className="login-card">
        <h1 className="login-title">Welcome to EstimaGPT</h1>
        <p className="login-subtitle">Your AI assistant for Xactimate and construction estimating</p>
        <button className="login-button" onClick={() => loginWithRedirect()}>
          Log In / Sign Up
        </button>
      </div>
    </div>
  );
};

export default Login;