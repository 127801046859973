import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import './Landing.css';

const Landing = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [typewriterText, setTypewriterText] = useState('');
  const fullText = "Your AI Assistant for Xactimate and Construction Estimating";
  const [textIndex, setTextIndex] = useState(0);

  useEffect(() => {
    if (textIndex < fullText.length) {
      const timeout = setTimeout(() => {
        setTypewriterText(prevText => prevText + fullText[textIndex]);
        setTextIndex(prevText => prevText + 1);
      }, 50);

      return () => clearTimeout(timeout);
    }
  }, [textIndex]);

  return (
    <div className="landing-container">
      <motion.header 
        className="header"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className="logo">EstimaGPT</div>
        <nav>
          {isAuthenticated ? (
            <Link to="/chat" className="login-button">Go to Chat</Link>
          ) : (
            <button className="login-button" onClick={() => loginWithRedirect()}>Log In</button>
          )}
        </nav>
      </motion.header>
      <main className="main-content">
        <section className="hero-section">
          <motion.div 
            className="hero-text"
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <h1 className="hero-title">EstimaGPT</h1>
            <h2 className="hero-subtitle">
              <span className="typewriter-text">{typewriterText}</span>
              <span className="cursor">|</span>
            </h2>
            <p className="hero-description">
              Streamline your estimating process with our advanced AI-powered tool. Get instant answers to your Xactimate and construction-related questions.
            </p>
            <div className="cta-buttons">
              {isAuthenticated ? (
                <Link to="/chat" className="primary-cta">Start Chatting</Link>
              ) : (
                <button className="primary-cta" onClick={() => loginWithRedirect()}>Get Started</button>
              )}
              <a href="#features" className="secondary-cta">Learn More</a>
            </div>
          </motion.div>
          <motion.div 
            className="hero-image"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <img src="/images/hero-image.png" alt="EstimaGPT in action" />
          </motion.div>
        </section>
        <section id="features" className="features-section">
          <h2 className="section-title">Key Features</h2>
          <div className="feature-grid">
            <motion.div 
              className="feature-card"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              <div className="feature-icon">🤖</div>
              <h3>AI-Powered Assistance</h3>
              <p>Get instant answers to your Xactimate and construction questions.</p>
            </motion.div>
            <motion.div 
              className="feature-card"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.8 }}
            >
              <div className="feature-icon">📊</div>
              <h3>Accurate Estimates</h3>
              <p>Improve the accuracy of your estimates with AI-driven insights.</p>
            </motion.div>
            <motion.div 
              className="feature-card"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 1 }}
            >
              <div className="feature-icon">⏱️</div>
              <h3>Time-Saving</h3>
              <p>Reduce research time and increase productivity in your estimating process.</p>
            </motion.div>
          </div>
        </section>
      </main>
      <motion.footer 
        className="footer"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 1.2 }}
      >
        <p>&copy; 2023 EstimaGPT. All rights reserved.</p>
      </motion.footer>
    </div>
  );
};

export default Landing;