import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import LemonSqueezy from './LemonSqueezy';

function Profile() {
  const { user, getAccessTokenSilently } = useAuth0();
  const [usageData, setUsageData] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [invoices, setInvoices] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isCancelling, setIsCancelling] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const token = await getAccessTokenSilently();

        const fetchWithAuth = async (url) => {
          const response = await fetch(url, {
            headers: { Authorization: `Bearer ${token}` },
          });
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.json();
        };

        const [usageData, subscriptionData, invoicesData] = await Promise.all([
          fetchWithAuth('https://api.estimagpt.com/usage-data'),
          fetchWithAuth('https://api.estimagpt.com/subscription-data'),
          fetchWithAuth('https://api.estimagpt.com/invoices')
        ]);

        setUsageData(usageData);
        setSubscriptionData(subscriptionData);
        setInvoices(invoicesData.invoices);
        setError(null);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [getAccessTokenSilently]);

  const handleCancelSubscription = async () => {
    try {
      setIsCancelling(true);
      const token = await getAccessTokenSilently();
      const response = await fetch('https://api.estimagpt.com/cancel-subscription', {
        method: 'POST',
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error('Failed to cancel subscription');
      }

      const result = await response.json();
      if (result.success) {
        setSubscriptionData(prevData => ({
          ...prevData,
          status: 'cancelled',
        }));
      } else {
        throw new Error(result.message || 'Failed to cancel subscription');
      }
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      setError('Failed to cancel subscription. Please try again later.');
    } finally {
      setIsCancelling(false);
    }
  };

  const handleCancelTrial = async () => {
    try {
      setIsCancelling(true);
      const token = await getAccessTokenSilently();
      const response = await fetch('https://api.estimagpt.com/cancel-trial', {
        method: 'POST',
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
      });

      if (!response.ok) {
        throw new Error('Failed to cancel trial');
      }

      const result = await response.json();
      if (result.success) {
        setSubscriptionData(prevData => ({
          ...prevData,
          status: 'cancelled',
        }));
      } else {
        throw new Error(result.message || 'Failed to cancel trial');
      }
    } catch (error) {
      console.error('Error cancelling trial:', error);
      setError('Failed to cancel trial. Please try again later.');
    } finally {
      setIsCancelling(false);
    }
  };

  const handleResubscribe = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch('https://api.estimagpt.com/resubscribe', {
        method: 'POST',
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: user.email })
      });

      if (!response.ok) {
        throw new Error('Failed to resubscribe');
      }

      const result = await response.json();
      if (result.success) {
        window.location.href = result.checkoutUrl;
      } else {
        throw new Error(result.message || 'Failed to resubscribe');
      }
    } catch (error) {
      console.error('Error resubscribing:', error);
      setError('Failed to resubscribe. Please try again later.');
    }
  };

  if (isLoading) {
    return <div className="container mx-auto mt-8 p-4">Loading...</div>;
  }

  if (error) {
    return <div className="container mx-auto mt-8 p-4 text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto mt-8 p-4">
      <h1 className="text-3xl font-bold mb-4">Profile & Billing</h1>
      <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h2 className="text-xl font-semibold mb-2">User Information</h2>
        <p><strong>Name:</strong> {user.name}</p>
        <p><strong>Email:</strong> {user.email}</p>
      </div>
      {subscriptionData && (
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <h2 className="text-xl font-semibold mb-2">Subscription Information</h2>
          <p><strong>Status:</strong> {subscriptionData.status}</p>
          <p><strong>Plan:</strong> {subscriptionData.plan}</p>
          <p><strong>Next Billing Date:</strong> {subscriptionData.nextBillingDate}</p>
          {subscriptionData.status === 'active' && (
            <button
              onClick={handleCancelSubscription}
              disabled={isCancelling}
              className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              {isCancelling ? 'Cancelling...' : 'Cancel Subscription'}
            </button>
          )}
          {subscriptionData.status === 'trial' && (
            <button
              onClick={handleCancelTrial}
              disabled={isCancelling}
              className="mt-4 bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              {isCancelling ? 'Cancelling...' : 'Cancel Trial'}
            </button>
          )}
          {subscriptionData.status === 'cancelled' && (
            <button
              onClick={handleResubscribe}
              className="mt-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              Resubscribe
            </button>
          )}
        </div>
      )}
      {usageData && (
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <h2 className="text-xl font-semibold mb-2">Token Consumption</h2>
          <p><strong>Input Tokens:</strong> {usageData.tokenConsumption?.input.toLocaleString() || 'N/A'}</p>
          <p><strong>Output Tokens:</strong> {usageData.tokenConsumption?.output.toLocaleString() || 'N/A'}</p>
          <p><strong>Total Cost:</strong> ${usageData.totalCost?.toFixed(2) || 'N/A'}</p>
          <p><strong>Total Queries:</strong> {usageData.totalQueries?.toLocaleString() || 'N/A'}</p>
          <p><strong>Image Queries:</strong> {usageData.imageQueries?.toLocaleString() || 'N/A'}</p>
        </div>
      )}
      {invoices.length > 0 && (
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 overflow-x-auto">
          <h2 className="text-xl font-semibold mb-4">Invoices</h2>
          <table className="w-full min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {invoices.map(invoice => (
                <tr key={invoice.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {new Date(invoice.created_at).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    ${parseFloat(invoice.total_usd).toFixed(2)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {invoice.status === 'paid' ? (
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        Paid
                      </span>
                    ) : (
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                        Unpaid
                      </span>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    {invoice.urls && invoice.urls.invoice_url && (
                      <a
                        href={invoice.urls.invoice_url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        Download
                      </a>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {(!subscriptionData || subscriptionData.status === 'cancelled') && (
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <h2 className="text-xl font-semibold mb-2">Start Your Subscription</h2>
          <p className="mb-4">Enjoy a 7-day free trial, then $20/month</p>
          <LemonSqueezy />
        </div>
      )}
    </div>
  );
}

export default Profile;