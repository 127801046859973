import React, { useState, useEffect } from 'react';
import { Route, Routes, Link, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Chat from './Chat';
import Login from './Login';
import Landing from './Landing';
import Profile from './Profile';
import ProtectedRoute from './ProtectedRoute';

function App() {
  const { isLoading, isAuthenticated, logout, getAccessTokenSilently } = useAuth0();
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const checkSubscription = async () => {
      if (isAuthenticated) {
        try {
          const token = await getAccessTokenSilently();
          const response = await fetch('https://api.estimagpt.com/check-subscription', {
            headers: { Authorization: `Bearer ${token}` },
          });
          const data = await response.json();
          setHasActiveSubscription(data.hasActiveSubscription);
        } catch (error) {
          console.error('Error checking subscription:', error);
        }
      }
    };

    checkSubscription();
  }, [isAuthenticated, getAccessTokenSilently]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {isAuthenticated && (
        <nav className="bg-blue-600 p-4">
          <div className="container mx-auto flex justify-between items-center">
            <Link to="/chat" className="text-white font-bold">EstimaGPT</Link>
            <div>
              <Link to="/profile" className="text-white mr-4">Profile & Billing</Link>
              <button
                onClick={() => logout({ returnTo: window.location.origin })}
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
              >
                Logout
              </button>
            </div>
          </div>
        </nav>
      )}
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route 
          path="/chat" 
          element={
            <ProtectedRoute 
              component={Chat} 
              isAllowed={hasActiveSubscription}
              redirectTo="/profile"
            />
          } 
        />
        <Route path="/profile" element={<ProtectedRoute component={Profile} />} />
      </Routes>
    </div>
  );
}

export default App;