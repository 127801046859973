import React, { useState, useEffect, useCallback, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ReactMarkdown from 'react-markdown';
import { motion } from 'framer-motion';
import { FaUser, FaRobot, FaCopy, FaSpinner, FaStop, FaPaperPlane, FaThumbsUp, FaThumbsDown, FaRedo, FaPaperclip } from 'react-icons/fa';
import { useAuth0 } from '@auth0/auth0-react';

function Chat() {
  const { user, getAccessTokenSilently } = useAuth0();
  const [userId] = useState(uuidv4());
  const [question, setQuestion] = useState('');
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [error, setError] = useState(null);
  const [image, setImage] = useState(null);
  const abortControllerRef = useRef(null);
  const chatEndRef = useRef(null);
  const chatContainerRef = useRef(null);

  useEffect(() => {
    const savedHistory = localStorage.getItem('chatHistory');
    if (savedHistory) {
      setChatHistory(JSON.parse(savedHistory));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('chatHistory', JSON.stringify(chatHistory));
    scrollToBottom();
  }, [chatHistory]);

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleImageUpload = useCallback((event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1];
        setImage(base64String);
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const removeImage = useCallback(() => {
    setImage(null);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (!question.trim() && !image) {
      setError('Please enter a question or upload an image before submitting.');
      return;
    }

    setIsLoading(true);
    setError(null);
    setResponse('');

    const payload = {
      question,
      user_id: userId,
      image: image,
    };

    abortControllerRef.current = new AbortController();

    try {
      const token = await getAccessTokenSilently();
      const response = await fetch('https://api.estimagpt.com/query', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(payload),
        signal: abortControllerRef.current.signal,
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      let accumulatedResponse = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        const lines = chunk.split('\n');

        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const data = line.slice(6);
            if (data === '[DONE]') {
              setIsLoading(false);
              return;
            }
            try {
              const parsedData = JSON.parse(data);
              if (parsedData.error) {
                setError(parsedData.error);
                setIsLoading(false);
                return;
              }
              if (parsedData.content) {
                accumulatedResponse += parsedData.content;
                setResponse(accumulatedResponse);
                scrollToBottom();
              }
            } catch (e) {
              console.error('Error parsing JSON:', e);
            }
          }
        }
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('Fetch error:', error);
        setError('Failed to connect to the server. Please try again later.');
      }
    } finally {
      setIsLoading(false);
    }
  }, [question, userId, image, getAccessTokenSilently]);

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  }, [handleSubmit]);

  const stopStreaming = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      setIsLoading(false);
    }
  }, []);

  const clearChat = useCallback(() => {
    setChatHistory([]);
    localStorage.removeItem('chatHistory');
  }, []);

  useEffect(() => {
    if (response && !isLoading) {
      setChatHistory(prev => [
        ...prev,
        { role: 'user', content: question, image: image },
        { role: 'assistant', content: response }
      ]);
      setQuestion('');
      setResponse('');
      setImage(null);
    }
  }, [response, isLoading, question, image]);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  const handleFeedback = (messageIndex, feedback) => {
    // Implement feedback logic here
    console.log(`Feedback for message ${messageIndex}: ${feedback}`);
  };

  const retryMessage = (messageIndex) => {
    const messageToRetry = chatHistory[messageIndex];
    if (messageToRetry.role === 'user') {
      setQuestion(messageToRetry.content);
      setImage(messageToRetry.image);
      handleSubmit();
    }
  };

  const exampleCards = [
    { 
      title: "Drain Channel", 
      question: "What is this drain channel?", 
      hasImage: true,
      imagePath: "/images/drain-channel.jpg"
    },
    { 
      title: "Skylight Tube", 
      question: "What is the line item for skylight tube?", 
      hasImage: false 
    },
    { 
      title: "Roof Shingles", 
      question: "How do I estimate replacing asphalt shingles?", 
      hasImage: false 
    },
  ];

  const handleCardClick = useCallback((card) => {
    setQuestion(card.question);
    if (card.hasImage && card.imagePath) {
      fetch(card.imagePath)
        .then(response => response.blob())
        .then(blob => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64data = reader.result.split(',')[1];
            setImage(base64data);
          };
          reader.readAsDataURL(blob);
        });
    } else {
      setImage(null);
    }
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 to-blue-300 py-6 flex flex-col">
      <div className="container mx-auto px-4 flex flex-col h-full">
        <div className="bg-white shadow-lg rounded-3xl p-6 mb-4">
          <h1 className="text-4xl font-bold text-blue-600 mb-8 text-center">EstimaGPT Assistant</h1>
          <div className="flex justify-between items-center mb-4">
            <span>Welcome, {user.name}!</span>
          </div>

          {/* Example Cards */}
          <div className="mb-8">
            <h2 className="text-2xl font-semibold text-blue-500 mb-4">Example Queries</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {exampleCards.map((card, index) => (
                <motion.div
                  key={index}
                  className="bg-blue-50 p-4 rounded-lg shadow-md cursor-pointer hover:shadow-lg transition-shadow duration-300"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handleCardClick(card)}
                >
                  <h3 className="text-lg font-semibold text-blue-700 mb-2">{card.title}</h3>
                  <p className="text-sm text-blue-600">{card.question}</p>
                  {card.hasImage && (
                    <div className="mt-2 h-20 flex items-center justify-center rounded overflow-hidden">
                      <img 
                        src={card.imagePath} 
                        alt={card.title} 
                        className="object-cover w-full h-full"
                      />
                    </div>
                  )}
                </motion.div>
              ))}
            </div>
          </div>
        </div>

        {/* Chat History */}
        {(chatHistory.length > 0 || response) && (
        <div className="bg-white shadow-lg rounded-3xl p-6 mb-4 flex-grow overflow-y-auto" ref={chatContainerRef}>
          {chatHistory.map((message, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className={`p-4 rounded-lg ${message.role === 'user' ? 'bg-blue-100 ml-auto' : 'bg-gray-100 mr-auto'} max-w-3/4 mb-4`}
            >
              <div className="flex items-start">
                {message.role === 'user' ? (
                  <FaUser className="text-blue-500 mr-2 mt-1" />
                ) : (
                  <FaRobot className="text-gray-500 mr-2 mt-1" />
                )}
                <div>
                  <p className="font-bold text-blue-700">{message.role === 'user' ? 'You:' : 'EstimaGPT:'}</p>
                  {message.image && (
                    <img src={`data:image/jpeg;base64,${message.image}`} alt="User uploaded" className="max-h-40 rounded-lg my-2" />
                  )}
                  <ReactMarkdown 
                    className="prose max-w-none"
                    components={{
                      p: ({node, ...props}) => <p className="mb-2" {...props} />,
                      h1: ({node, ...props}) => <h1 className="text-2xl font-bold mt-4 mb-2" {...props} />,
                      h2: ({node, ...props}) => <h2 className="text-xl font-bold mt-3 mb-2" {...props} />,
                      h3: ({node, ...props}) => <h3 className="text-lg font-bold mt-2 mb-1" {...props} />,
                      ul: ({node, ...props}) => <ul className="list-disc pl-4 mb-2" {...props} />,
                      ol: ({node, ...props}) => <ol className="list-decimal pl-4 mb-2" {...props} />,
                      li: ({node, ...props}) => <li className="mb-1" {...props} />,
                    }}
                  >
                    {message.content}
                  </ReactMarkdown>
                </div>
              </div>
              <div className="mt-2 flex space-x-2">
                <button
                  onClick={() => copyToClipboard(message.content)}
                  className="text-blue-500 hover:text-blue-700"
                >
                  <FaCopy />
                </button>
                {message.role === 'assistant' && (
                  <>
                    <button
                     onClick={() => handleFeedback(index, 'thumbsUp')}
                     className="text-green-500 hover:text-green-700"
                   >
                     <FaThumbsUp />
                   </button>
                   <button
                     onClick={() => handleFeedback(index, 'thumbsDown')}
                     className="text-red-500 hover:text-red-700"
                   >
                     <FaThumbsDown />
                   </button>
                 </>
               )}
               <button
                 onClick={() => retryMessage(index)}
                 className="text-yellow-500 hover:text-yellow-700"
               >
                 <FaRedo />
               </button>
             </div>
           </motion.div>
         ))}
         {response && (
           <motion.div
             initial={{ opacity: 0, y: 20 }}
             animate={{ opacity: 1, y: 0 }}
             className="p-4 rounded-lg bg-gray-100 mr-auto max-w-3/4 mb-4"
           >
             <div className="flex items-start">
               <FaRobot className="text-gray-500 mr-2 mt-1" />
               <div>
                 <p className="font-bold text-blue-700">EstimaGPT:</p>
                 <ReactMarkdown 
                   className="prose max-w-none"
                   components={{
                     p: ({node, ...props}) => <p className="mb-2" {...props} />,
                     h1: ({node, ...props}) => <h1 className="text-2xl font-bold mt-4 mb-2" {...props} />,
                     h2: ({node, ...props}) => <h2 className="text-xl font-bold mt-3 mb-2" {...props} />,
                     h3: ({node, ...props}) => <h3 className="text-lg font-bold mt-2 mb-1" {...props} />,
                     ul: ({node, ...props}) => <ul className="list-disc pl-4 mb-2" {...props} />,
                     ol: ({node, ...props}) => <ol className="list-decimal pl-4 mb-2" {...props} />,
                     li: ({node, ...props}) => <li className="mb-1" {...props} />,
                   }}
                 >
                   {response}
                 </ReactMarkdown>
               </div>
             </div>
             <button
               onClick={() => copyToClipboard(response)}
               className="mt-2 text-blue-500 hover:text-blue-700"
             >
               <FaCopy />
             </button>
           </motion.div>
         )}
         <div ref={chatEndRef} />
       </div>
     )}
     {/* Input Area */}
     <div className="bg-white shadow-lg rounded-3xl p-6">
       <div className="flex items-center space-x-2">
         <motion.input
           initial={{ opacity: 0, y: 20 }}
           animate={{ opacity: 1, y: 0 }}
           transition={{ duration: 0.5 }}
           className="flex-grow px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-400"
           type="text"
           value={question}
           onChange={(e) => setQuestion(e.target.value)}
           onKeyDown={handleKeyDown}
           placeholder="Ask about Xactimate line items or construction..."
         />
         <motion.button
           whileHover={{ scale: 1.05 }}
           whileTap={{ scale: 0.95 }}
           onClick={isLoading ? stopStreaming : handleSubmit}
           className={`p-2 text-white rounded-full focus:outline-none focus:ring-2 focus:ring-opacity-50 transition-colors duration-300 ${
             isLoading ? 'bg-red-500 hover:bg-red-600 focus:ring-red-400' : 'bg-blue-500 hover:bg-blue-600 focus:ring-blue-400'
           }`}
         >
           {isLoading ? (
             <FaStop className="w-5 h-5" />
           ) : (
             <FaPaperPlane className={`w-5 h-5 ${isLoading ? 'animate-pulse' : ''}`} />
           )}
         </motion.button>
       </div>
       <div className="flex items-center space-x-4 mt-2">
         <label className="flex items-center space-x-2 cursor-pointer">
           <FaPaperclip className="text-blue-500" />
           <span className="text-sm font-medium text-blue-500">Upload Image</span>
           <input
             type="file"
             accept="image/*"
             onChange={handleImageUpload}
             className="hidden"
           />
         </label>
         {isLoading && (
           <span className="text-blue-500 flex items-center">
             <FaSpinner className="animate-spin mr-2" />
             Processing...
           </span>
         )}
       </div>
       {image && (
         <motion.div
           initial={{ opacity: 0, scale: 0.8 }}
           animate={{ opacity: 1, scale: 1 }}
           className="relative mt-4"
         >
           <img src={`data:image/jpeg;base64,${image}`} alt="Uploaded" className="max-h-40 rounded-lg" />
           <button
             onClick={removeImage}
             className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 text-xs"
           >
             X
           </button>
         </motion.div>
       )}
       {error && (
         <motion.div
           initial={{ opacity: 0, y: -20 }}
           animate={{ opacity: 1, y: 0 }}
           className="text-red-500 mt-4"
         >
           {error}
         </motion.div>
       )}
       {chatHistory.length > 0 && (
         <motion.button
           whileHover={{ scale: 1.05 }}
           whileTap={{ scale: 0.95 }}
           onClick={clearChat}
           className="mt-4 px-6 py-2 font-bold text-white bg-gradient-to-r from-red-500 to-red-700 rounded-full hover:from-red-600 hover:to-red-800 focus:outline-none focus:ring-2 focus:ring-red-400 focus:ring-opacity-50 transition-colors duration-300"
         >
           Clear Chat History
         </motion.button>
       )}
     </div>
   </div>
 </div>
);
}

export default Chat;