import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const LemonSqueezy = () => {
  const { user, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.lemonsqueezy.com/js/lemon.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const startTrial = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch('https://api.estimagpt.com/start-trial', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: user.email })
    });
    const data = await response.json();
    if (data.success) {
      window.createLemonSqueezy();
      window.LemonSqueezy.Setup({
        eventHandler: async (event) => {
          if (event.event === 'Checkout.Success') {
            await fetch('https://api.estimagpt.com/subscription-created', {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ checkoutData: event.data })
            });
          }
        }
      });
      window.LemonSqueezy.Url.Open(data.checkoutUrl);
    }
  };

  return (
    <button onClick={startTrial} className="bg-yellow-400 hover:bg-yellow-500 text-white font-bold py-2 px-4 rounded">
      Start 7-Day Free Trial
    </button>
  );
};

export default LemonSqueezy;